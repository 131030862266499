<template>
  <ul class="selectList">
    <li class="selectList-item" v-if="isGmoOrOffice">
      <router-link
        class="selectList-btn selectList-btn-green"
        :to="{ name: 'SettingUserRegisterClient' }"
      >
        <p class="selectList-title">client</p>
        <p class="selectList-description">運営者アカウントの登録</p>
      </router-link>
    </li>
    <li class="selectList-item" v-if="isGmo || isOfficeWithShop">
      <router-link
        class="selectList-btn selectList-btn-red"
        :to="{ name: 'SettingUserRegisterShop' }"
      >
        <p class="selectList-title">shop</p>
        <p class="selectList-description">加盟店アカウントの登録</p>
      </router-link>
    </li>
    <li class="selectList-item" v-if="isGmo">
      <router-link
        class="selectList-btn selectList-btn-blue"
        :to="{ name: 'SettingUserRegisterMaster' }"
      >
        <p class="selectList-title">master</p>
        <p class="selectList-description">管理者アカウントの登録</p>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { RoleConstants } from '@/constants/role';

export default {
  name: 'SettingUserRegisterSelect',
  data: function() {
    return {};
  },
  mounted: function() {
    this.$emit('footer-hide');
  },
  computed: {
    ...mapGetters({
      userChildRoles: 'auth/userChildRoles'
    }),
    isGmo() {
      return this.$permission.isGmo();
    },
    isGmoOrOffice() {
      return this.$permission.isGmoOrOffice();
    },
    isOfficeWithShop() {
      return this.$permission.isOffice() && this.userChildRoles.includes(RoleConstants.ROLE_SHOPS_OFFICE);
    }
  },
};
</script>
